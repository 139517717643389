import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  companyStatuses: {
    isLoading: false,
    error: false,
    data: [],
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    startCompanyStatusesLoading(state) {
      state.companyStatuses.isLoading = true;
    },

    hasCompanyStatusesError(state, action) {
      state.companyStatuses.isLoading = false;
      state.companyStatuses.error = action.payload;
    },

    getCompanyStatusesSuccess(state, action) {
      state.companyStatuses.isLoading = false;
      state.companyStatuses.data = action.payload;
    },
  },
});

export default slice.reducer;

export function getCompanyStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startCompanyStatusesLoading());
    try {
      const response = await axios.get('/overview/company-status');

      dispatch(slice.actions.getCompanyStatusesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasCompanyStatusesError(error));
    }
  };
}

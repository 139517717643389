import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import companyReducer from './slices/company';
import investorReducer from './slices/investor';
import transactionReducer from './slices/transaction';
import dashboardReducer from './slices/dashboard';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  company: companyReducer,
  investor: investorReducer,
  transaction: transactionReducer,
  dashboard: dashboardReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };

import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  list: [],
  selected: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCompanyListSuccess(state, action) {
      const { companies } = action.payload;
      state.isLoading = false;
      state.list = companies;
    },

    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },
  },
});

export default slice.reducer;

export function getCompanyList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/companies');

      dispatch(slice.actions.getCompanyListSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompany(companyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/companies/${companyId}`);

      dispatch(slice.actions.getCompanySuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

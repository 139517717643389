function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: {
    root: '/overview',
    dashboard: '/overview/dashboard',
  },
  management: {
    root: '/management',
    company: {
      list: '/management/companies',
      detail: (id) => `/management/companies/${id}`,
    },
    transaction: {
      list: '/management/transactions',
      detail: (id) => `/management/transactions/${id}`,
    },
    investor: {
      list: '/management/investors',
      detail: (id) => `/management/investors/${id}`,
    },
  },
  offers: '/offers',
};

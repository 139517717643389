import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const LogoStyle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 900,
  fontSize: '20px',
  marginLeft: '10px',
  textDecoration: 'none',
}));

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ includeText = false, sx }) {
  return (
    <Stack direction='row' alignItems='center'>
      <Box component='img' alt='logo' src='/static/brand/logo_full.svg' sx={{ width: 40, height: 40, ...sx }} />
      {includeText && <LogoStyle>beyondcash</LogoStyle>}
    </Stack>
  );
}

export function LogoIcon({ sx }) {
  return <Box component='img' alt='logo' src='/static/brand/logo_full.svg' sx={{ width: 40, height: 40, ...sx }} />;
}

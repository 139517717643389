import offerIcon from '@iconify/icons-mdi/offer';
import { Icon } from '@iconify/react';
import SvgIconStyle from 'components/SvgIconStyle';
import { PATH_DASHBOARD } from 'routes/paths';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  company: getIcon('ic_banking'),
  transaction: getIcon('ic_ecommerce'),
  investor: getIcon('ic_user'),
  offers: getIcon('ic_booking'),
};

const sidebarConfig = [
  {
    subheader: 'overview',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.overview.dashboard,
        icon: ICONS.dashboard,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'Companies',
        path: PATH_DASHBOARD.management.company.list,
        icon: ICONS.company,
      },
      {
        title: 'Transactions',
        path: PATH_DASHBOARD.management.transaction.list,
        icon: ICONS.transaction,
      },
      {
        title: 'Investors',
        path: PATH_DASHBOARD.management.investor.list,
        icon: ICONS.investor,
      },
    ],
  },
  {
    subheader: 'others',
    items: [
      {
        title: 'Offers',
        path: PATH_DASHBOARD.offers,
        icon: <Icon icon={offerIcon} width={30} height={30} />,
      },
    ],
  },
];

export default sidebarConfig;

import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/overview/dashboard' replace /> },
        {
          path: 'overview',
          children: [
            { element: <Navigate to='/overview/dashboard' replace /> },
            { path: 'dashboard', element: <Dashboard /> },
          ],
        },
        {
          path: 'management',
          children: [
            { element: <Navigate to='/management/companies' replace /> },
            {
              path: 'companies',
              children: [{ element: <CompanyList /> }, { path: ':companyId', element: <CompanyDetail /> }],
            },
            {
              path: 'transactions',
              children: [{ element: <TransactionList /> }, { path: ':transactionId', element: <TransactionDetail /> }],
            },
            {
              path: 'investors',
              children: [{ element: <InvestorList /> }, { path: ':investorId', element: <InvestorDetail /> }],
            },
          ],
        },
        {
          path: 'offers',
          element: <OfferList />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

const Login = Loadable(lazy(() => import('pages/authentication/LoginView')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('pages/authentication/VerifyCode')));
const Dashboard = Loadable(lazy(() => import('pages/overview/DashboardView')));
const CompanyList = Loadable(lazy(() => import('pages/management/company/CompanyListView')));
const CompanyDetail = Loadable(lazy(() => import('pages/management/company/CompanyDetailView')));
const OfferList = Loadable(lazy(() => import('pages/OfferListView')));
const TransactionList = Loadable(lazy(() => import('pages/management/transaction/TransactionListView')));
const TransactionDetail = Loadable(lazy(() => import('pages/management/transaction/TransactionDetailView')));
const InvestorList = Loadable(lazy(() => import('pages/management/investor/InvestorListView')));
const InvestorDetail = Loadable(lazy(() => import('pages/management/investor/InvestorDetailView')));
const Maintenance = Loadable(lazy(() => import('pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const NotFound = Loadable(lazy(() => import('pages/Page404')));
